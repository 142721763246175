import { FileContentResultModel } from '../shared/models/file-content-result-model';
import { filterXSS } from 'xss';

export const CONTEXT_KEY_REGEX = '^\\S*$';
export const CONTEXT_VALUE_REGEX = '^[^\\"\\`\\;]*$';
export const ENTITY_NAME_REGEX =
  '^[a-zA-Zа-яА-ЯёЁ_\u0621-\u0655\u0671-\u06d3\u00c1-\u00fc][a-zA-Zа-яА-ЯёЁ0-9_\u0621-\u0655\u0671-\u06d3\u00c1-\u00fc\u0660-\u0669\u06f0-\u06f9]*$';
export const PROJECT_NAME_REGEX = '^[a-zA-Z0-9-_.]*$';
export const LLM_CONFIGURATION_NAME_REGEX = '^[a-zA-Z0-9_-]{1,200}$';
export const INTENT_NAME_REGEX = '^[^\'"<>/\\\\]+$';
export const CUSTOM_DATA_KEY_REGEX = '^[a-zA-Z_$][a-zA-Z0-9_$]*$';
export const VA_NODE_VA_INPUT_TYPE_ID = '0124a1a8-b143-4c6d-bb85-3d16ff7d481e';
export const INTENT_RECOGNITION_NODE_VA_INPUT_TYPE_ID = '8918d9d0-8fdc-4c4f-9646-05cf51bb1cf0';
export const DEFAULT_CHANNEL_TYPE_ID = '7425542e-423e-4171-9ca7-e3f0a50b2fc0';
export const IVR_CHANNEL_TYPE_ID = '851b2b91-2d1a-4e70-8abf-e698cce49f13';
export const CONDITION_NODE_CONDITION_INPUT_TYPE_ID = 'df7d3529-fad1-4399-82a9-dd1cce9f9674';
export const CONDITION_NODE_INPUT_IS_NULL_KEY = 'is null';
export const DEFAULT_CHANNEL_NAME = 'DefaultChannel';

export const CONDITION_NODE_VARIABLE_INPUT_TYPE_ID = 'ad44a667-a591-4a69-ae5b-1219c68268f3';
export const ASK_MISSING_SLOT_NODE_ENTITY_INPUT_TYPE_ID = 'e1c0a884-8cad-4530-9519-eb402871184b';
export const ASK_ATTACHMENT_NODE_FILE_CATEGORY_INPUT_TYPE_ID =
  'dcf40a40-88be-45b2-b104-16544ae51015';
export const ASK_ATTACHMENT_NODE_RECEIVED_FILE_OUTPUT_TYPE_ID =
  '5e5af6de-89d7-4b93-83a7-3f51fe426745';
export const ASK_NODE_UTTERANCE_OUTPUT_TYPE_ID = 'ca3212ce-9e03-4c3b-af02-c808b6689680';
export const CONFIRMATION_NODE_LANGUAGE_INPUT_TYPE_ID = '390b3177-a3c1-4e3a-b329-d7844cf18fc7';
export const HTTP_NODE_URL_INPUT_TYPE_ID = '68c890b0-5caf-468c-8f30-7f9b306faf3f';
export const HTTP_NODE_CONTENT_TYPE_INPUT_TYPE_ID = 'd893fc8c-7e12-416c-b32d-351570506da6';
export const VARIABLE_NODE_OPERATION_INPUT_TYPE_ID = '740321bc-93a1-48c3-be42-7be327617260';
export const LIST_FILTER_NODE_FILTER_KEY_INPUT_TYPE_ID = 'f4c6351a-9e92-431b-9205-be3adf9ce0a4';
export const LIST_FILTER_NODE_FILTER_VALUE_INPUT_TYPE_ID = '2fccae15-3f9b-45ae-9b49-5df2f3cee7bf';
export const CUSTOM_DATA_NODE_HTTP_BODY_PROPERTIES_INPUT_TYPE_ID =
  '63b81f24-b050-4e4c-b634-af7da180d8ae';
export const CUSTOM_DATA_NODE_HTTP_HEADER_PROPERTIES_INPUT_TYPE_ID =
  '8d1cf30a-2115-4af9-8179-173445a80479';
export const CODE_NODE_CODE_INPUT_TYPE_ID = '0fda4b29-8d6b-41d2-8adf-195a3b8f91bf';
export const INTENT_RECOGNITION_NODE_UTTERANCE_INPUT_TYPE_ID =
  '6903b9ec-4459-4076-886a-de74785d3b2e';
export const INTENT_RECOGNITION_NODE_VIRTUAL_AGENT_INPUT_TYPE_ID =
  '8918d9d0-8fdc-4c4f-9646-05cf51bb1cf0';
export const LIVE_CHAT_TRANSFER_NODE_QUEUE_INPUT_TYPE_ID = 'a9111eb0-b2a5-46c3-9818-bf53f4ab706c';
export const LIVE_CHAT_TRANSFER_NODE_CONVERSATION_SUMMARY_INPUT_TYPE_ID =
  'd1f6824d-d54e-49c9-ab2f-1c6531f3f01d';
export const LIVE_CHAT_TRANSFER_NODE_TRY_ATTEMPT_NOTIFICATION_INPUT_TYPE_ID =
  'ce4c1e1d-0337-40bc-b77b-b05ec70e57ef';
export const LIVE_CHAT_TRANSFER_NODE_NO_LIVE_AGENT_NOTIFICATION_INPUT_TYPE_ID =
  '33976d50-da13-4894-83d4-241dc4d4aef5';
export const CALL_TRANSFER_NODE_TRANSFER_TO_INPUT_TYPE_ID = '703f593b-eb50-40da-b59b-76cd5c4c360d';
export const WAIT_NODE_DURATION_INPUT_TYPE_ID = 'bccfe2bb-5d08-4f03-9fec-fb1e52b53df7';
export const SUBFLOW_NODE_NAME_INPUT_TYPE_ID = 'd3599d8b-09c4-49a4-a65f-fa559c5a97ef';
export const VIRTUAL_AGENT_NODE_VIRTUAL_AGENT_INPUT_TYPE_ID =
  '0124a1a8-b143-4c6d-bb85-3d16ff7d481e';
export const VOICE_BIOMETRY_NODE_USER_CODE_INPUT_TYPE_ID = '6d8a8cb9-34da-430e-a2c7-fd7cfc349ba6';
export const GENERATIVE_QUESTION_ANSWERING_NODE_CONTENT_INPUT_TYPE_ID =
  '5cffd04a-8c8f-4573-84a5-e03b021d6bfe';
export const WEBHOOK_LISTENER_NODE_WEBHOOK_URL_INPUT_TYPE_ID =
  'a5852103-7d54-4491-9f60-8df2c80acb3b';

export const ASK_NODE_TIMEOUT_INPUT_TYPE_ID = '79114cb7-abd9-4ce8-b68c-d8af94f85049';
export const ASK_ATTACHMENT_NODE_TIMEOUT_INPUT_TYPE_ID = 'ccc9d4b6-a878-43d8-b220-f987ba6dbc9b';
export const CONFIRMATION_NODE_TIMEOUT_INPUT_TYPE_ID = '3e2f643a-24db-4e75-9785-7967c95cadc9';
export const VOICE_BIOMETRY_NODE_TIMEOUT_DURATION_INPUT_TYPE_ID =
  'f153b4ef-0cd0-44af-a263-e2445645c13f';
export const GENERATIVE_QUESTION_ANSWERING_NODE_SIMILARITY_THRESHOLD_INPUT_TYPE_ID =
  'f2860c9b-f6e3-412e-bab4-e661ed0274da';

export const LIVE_CALL_TRANSFER_NODE_TRANSFER_MESSAGE_NOTIFICATION_INPUT_TYPE_ID =
  '1d675d31-2a94-44ee-9b77-1e94e31f3365';
export const LIVE_CALL_TRANSFER_NODE_HTTP_BODY_PROPERTIES_INPUT_TYPE_ID =
  '3055fab8-a7af-468f-8930-e56f1005de0d';
export const LIVE_CALL_TRANSFER_NODE_HTTP_HEADER_PROPERTIES_INPUT_TYPE_ID =
  '5e7ad407-c8a2-404a-8669-5f98b04c7727';

// NODE TYPE IDS
export const ASK_NODE_TYPE_ID = 'f052a019-5f47-4389-92c0-8ac187980d0f';
export const ASK_ATTACHMENT_NODE_TYPE_ID = '00e1b34c-d943-4247-b1e5-b314c9dd214d';
export const ASK_MISSING_SLOT_NODE_TYPE_ID = '0772fe68-f22a-4efd-852b-3711d2052214';
export const CALL_TRANSFER_NODE_TYPE_ID = 'bafa3f7a-ec88-4983-8b50-718575a04457';
export const CODE_NODE_TYPE_ID = 'e623b72b-ce68-47ff-81ab-d3d280232d1e';
export const CONDITION_NODE_TYPE_ID = 'c0a1ef57-9b56-415e-a823-b8984fca9de7';
export const CONFIRMATION_NODE_TYPE_ID = 'e668890e-a5a2-4d71-9b5b-2c5720259ca5';
export const CUSTOM_DATA_NODE_TYPE_ID = '3d179c9e-0ac4-4408-a04d-8e437da5a845';
export const LIST_FILTER_NODE_TYPE_ID = '07d1d741-44fc-498c-8d0a-d4123c19d493';
export const VOICE_BIOMETRY_NODE_TYPE_ID = '50509c2a-8b0d-4cfa-bd35-ce10e62a819a';
export const LIVE_CHAT_TRANSFER_NODE_TYPE_ID = 'cc6cf24b-1ecb-401e-8470-ab40a1d99b03';
export const INTENT_RECOGNITION_NODE_TYPE_ID = '8afd7cb1-1c45-4ca7-b281-b51eaa75025d';
export const END_NODE_TYPE_ID = 'e07f6b87-aa01-4344-a560-2eca47dc6ebf';
export const HTTP_NODE_TYPE_ID = 'ef185877-5276-46c9-a0df-d24678f2174a';
export const SAY_NODE_TYPE_ID = '16ea11e0-3840-4b6e-82d1-abc36fc7c154';
export const SUBFLOW_NODE_TYPE_ID = '55cb5eb7-014f-4b8e-88ea-c173883f8dd7';
export const START_NODE_TYPE_ID = '06908895-80e6-4836-8b79-9001564198ea';
export const VARIABLE_NODE_TYPE_ID = '6e04ec47-e5df-41a9-a066-75ce75d549d9';
export const VIRTUAL_AGENT_NODE_TYPE_ID = '8803b1fd-329a-47e9-8b1a-af51b8b66b9d';
export const WAIT_NODE_TYPE_ID = '3869fd6f-24fb-426c-8bd6-a3fb041939a9';
export const GENERATIVE_QUESTION_ANSWERING_NODE_TYPE_ID = 'be1477e5-748b-4297-aee7-c19911507ec5';
export const LANGUAGE_CONDITION_NODE_TYPE_ID = 'a3b57573-7eef-466f-8983-80ef8a00e8f6';
export const WEBHOOK_LISTENER_NODE_TYPE_ID = '89fff52d-f02d-4005-9fb5-fa627b4ff9e0';
export const LIVE_CALL_TRANSFER_NODE_TYPE_ID = 'b13b873f-3d62-42a9-a472-193af574426a';

export const FILE_RESPONSE_TYPE_ID = 'ffe4c8ff-18e1-4c25-9d25-1d7759ef257b';

export const NODE_FIELD_TYPE_IDS_CONTAINING_TEXT_WITH_ID = [
  LIVE_CHAT_TRANSFER_NODE_TRY_ATTEMPT_NOTIFICATION_INPUT_TYPE_ID,
  LIVE_CHAT_TRANSFER_NODE_NO_LIVE_AGENT_NOTIFICATION_INPUT_TYPE_ID,
  LIVE_CALL_TRANSFER_NODE_TRANSFER_MESSAGE_NOTIFICATION_INPUT_TYPE_ID,
];

export const ZIP_FILE_EXTENSION_NAME = '.zip';

export const maxResultCount = 25;
export const maxNumberOfEditorChars = 5000;
export const maxRemainingNumberOfEditorChars = 100;
export const projectNameMaxLength = 200;

export const integrationIconDictionary: {
  icons: { platform: string; provider: string; iconClass: string }[];
  defaultIconClass: string;
  getClass: (platform: string, provider: string) => string;
} = {
  icons: [
    { platform: 'WhatsApp', provider: 'Connexease', iconClass: 'fab fa-whatsapp' },
    { platform: 'Telegram', provider: 'Connexease', iconClass: 'fab fa-telegram' },
    { platform: 'FaceBook', provider: 'Facebook', iconClass: 'fab fa-facebook' },
    { platform: 'Instagram', provider: 'Facebook', iconClass: 'fab fa-instagram' },
    { platform: 'WhatsApp', provider: 'Facebook', iconClass: 'fab fa-whatsapp' },
    { platform: 'WhatsApp', provider: 'InfoBip', iconClass: 'fab fa-whatsapp' },
    { platform: 'FaceBook', provider: 'Azure', iconClass: 'fab fa-microsoft' },
    { platform: 'Slack', provider: 'Azure', iconClass: 'fab fa-microsoft' },
    { platform: 'Teams', provider: 'Azure', iconClass: 'fab fa-microsoft' },
    { platform: 'WebChat', provider: 'Azure', iconClass: 'fab fa-microsoft' },
    { platform: 'Telegram', provider: 'Azure', iconClass: 'fab fa-microsoft' },
    { platform: 'Sms', provider: 'Twilio', iconClass: 'fa fa-envelope' },
    { platform: 'Threads', provider: 'Facebook', iconClass: 'fab fa-threads' },
    { platform: 'WebChat', provider: 'Sestek', iconClass: 'far fa-comments' },
    { platform: 'WhatsApp', provider: 'Unifonic', iconClass: 'fab fa-whatsapp' },
    { platform: 'WhatsApp', provider: '360 Dialog', iconClass: 'fab fa-whatsapp' },
    { platform: 'WhatsApp', provider: 'Twilio', iconClass: 'fab fa-whatsapp' },
    { platform: 'WhatsApp', provider: 'Sprinklr', iconClass: 'fab fa-whatsapp' },
    { platform: 'FaceBook', provider: 'Sprinklr', iconClass: 'fab fa-facebook' },
    { platform: 'Avatar', provider: 'Uneeq', iconClass: 'fas fa-user-crown' },
    { platform: 'Email', provider: 'Azure', iconClass: 'fa-regular fa-envelope' },
    { platform: 'Speech Recognition', provider: 'Sestek', iconClass: 'fas fa-tty' },
    { platform: 'Text-to-Speech', provider: 'Sestek', iconClass: 'far fa-comment-dots' },
    { platform: 'Voice Biometrics', provider: 'Sestek', iconClass: 'far fa-shield-check' },
    { platform: 'Sentiment', provider: 'Sestek', iconClass: 'fal fa-comment-smile' },
    { platform: 'Text-Language Detection', provider: 'Sestek', iconClass: 'fas fa-input-text' },
    { platform: 'Text-to-Speech', provider: 'Microsoft', iconClass: 'far fa-comment-dots' },
    { platform: 'Text-to-Speech', provider: 'ElevenLabs', iconClass: 'far fa-comment-dots' },
    { platform: 'Live Chat', provider: 'Generic', iconClass: 'fas fa-headset' },
    { platform: 'Live Chat', provider: 'Alotech', iconClass: 'fas fa-headset' },
    { platform: 'Live Chat', provider: 'Aacc', iconClass: 'fas fa-headset' },
    { platform: 'Live Chat', provider: 'Genesys', iconClass: 'fas fa-headset' },
    { platform: 'Live Chat', provider: 'GenesysEngage', iconClass: 'fas fa-headset' },
    { platform: 'Live Chat', provider: 'Tegsoft', iconClass: 'fas fa-headset' },
    { platform: 'Live Chat', provider: 'Twilio', iconClass: 'fas fa-headset' },
    { platform: 'Live Chat', provider: 'Amazon', iconClass: 'fas fa-headset' },
    { platform: 'Translation', provider: 'Google', iconClass: 'far fa-language' },
  ],
  defaultIconClass: 'fas fa-sync-alt',
  getClass: function (platform: string, provider: string) {
    const icon = this.icons.find((i) => i.platform === platform && i.provider === provider);
    return icon?.iconClass ?? this.defaultIconClass;
  },
};

export const tableContent = (localize) => {
  return {
    emptyMessage: localize.instant('Conversation::NoData'),
    totalMessage: localize.instant('::Total'),
    selectedMessage: localize.instant('Conversation::SelectedMessage'),
  };
};

export const guid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const isObject = (item) => {
  return item && typeof item === 'object' && !Array.isArray(item);
};

const mergeDeep = (target, source) => {
  const output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
};

const lineConvert = (keys: string, valueToInsert: string) => {
  const tempObject = {};
  let container = tempObject;
  keys.split('.').map((k, i, values) => {
    container = container[k] = i === values.length - 1 ? valueToInsert : {};
  });
  return tempObject;
};

export const convertToNestedObject = (object: { [key: string]: any }) => {
  let result = {};
  Object.keys(object).forEach((key) => {
    const convertedLine = lineConvert(key, object[key]);
    result = mergeDeep(result, convertedLine);
  });
  return result;
};

export const flattenObject = (obj: any, newObj: any) => {
  if (obj && newObj) {
    Object.keys(obj).forEach((key: string) => {
      if (Array.isArray(obj[key])) {
        newObj[key] = obj[key].map((childObj) =>
          isObject(childObj) ? flattenObject(childObj, {}) : childObj,
        );
      } else if (isObject(obj[key])) {
        const childObj = {};
        Object.keys(obj[key]).forEach((k: string) => {
          childObj[key + '.' + k] = obj[key][k];
        });
        flattenObject(childObj, newObj);
      } else {
        newObj[key] = obj[key];
      }
    });
  }
  return newObj;
};

export const sortJson = (json: any) => {
  return Object.keys(json)
    .sort()
    .reduce((obj, key) => {
      obj[key] = json[key];
      return obj;
    }, {});
};

export const capitalizeFirstLetter = (input: string) => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const downloadCSVFile = (data: FileContentResultModel, downloadedFileName: string) => {
  const csvContent = b64DecodeUnicode(data.fileContentResult.fileContents);
  const blob = new Blob([csvContent], { type: 'data:application/octet-stream;base64' });
  const downloadURL = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadURL;
  link.download = downloadedFileName;
  link.click();
  link.remove();
};

export const b64DecodeUnicode = (str: string) => {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), (c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
};

export const formatText = (text: string, maxWidth: number): string[] => {
  const sections: string[] = [];
  const words = text.split(' ');
  let temp = '';

  words.forEach((item, index) => {
    if (temp.length > 0) {
      const concat = temp + ' ' + item;

      if (concat.length > maxWidth) {
        sections.push(temp);
        temp = '';
      } else {
        if (index === words.length - 1) {
          sections.push(concat);
          return;
        } else {
          temp = concat;
          return;
        }
      }
    }

    if (index === words.length - 1) {
      sections.push(item);
      return;
    }

    if (item.length < maxWidth) {
      temp = item;
    } else {
      sections.push(item);
    }
  });

  return sections;
};

export const isRtl = (config) => {
  const localize = config.getOne('localization');
  return localize?.currentCulture.cultureName === 'ar';
};

export const isLanguageTurkish = (config) => {
  const localize = config.getOne('localization');
  return localize?.currentCulture.cultureName === 'tr';
};

export const sanitizeValueIfHtml = (value) => {
  if (typeof value === 'string') {
    value = filterXSS(value, {
      css: false,
      whiteList: { b: [], em: [], u: [] },
    });
  }

  if (
    value?.defaultValue &&
    (typeof value.defaultValue === 'string' || value.defaultValue instanceof String)
  ) {
    value.defaultValue = filterXSS(value.defaultValue, {
      css: false,
      whiteList: { b: [], em: [], u: [] },
    });
  }

  return value;
};
